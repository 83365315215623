import React from "react";

export const data_web_design = {
  what_we_do: {
    title: "Elementy webdesignu",
    text: [
      <>
        <b>Moodboard</b> - dawka inspiracji, która w kreatywny sposób przedstawi
        koncepcję Twojego projektu. Pozwól nam uporządkować swoje myśli, a my
        zaprezentujemy je Twoim klientom w oryginalny i przejrzysty sposób
      </>,
      <>
        <b>Makiety</b> – szkic Twojej strony internetowej, który w profesjonalny
        sposób pokaże działalność Twojej marki. Makiety funkcjonalne umożliwią
        również korekty, aby projekt strony spełniał wszystkie Twoje oczekiwania
      </>,
      <>
        <b>Widoki</b>– wygląd strony www jest wizytówką Twojego biznesu w
        internecie. Dlatego zadbamy, aby był jak najbardziej czytelny i zachęcał
        użytkowników do pozostania na niej. Twój sukces jest naszym sukcesem.
      </>,
      <>
        <b>Zaawansowane animacje </b>– wzbogacą one przekaz informacji. Sprawią
        też, że Twoja strona internetowa będzie wizualnie atrakcyjna dla Twoich
        przyszłych klientów. Spersonalizowane animacje to nasza specjalność!
      </>,
    ],
  },
  our_process: {
    title: "Nasz proces projektowy",
    steps: [
      {
        title: "Badanie rynku",
        list: [
          "Analiza konkurencji",
          "Poznanie biznesu klienta",
          "Stworzenie Moodboardów",
        ],
      },
      {
        title: "Projektowanie UX",
        list: ["Ścieżka użytkownika", "Modele szkieletowa", "Prototypowanie"],
      },
      {
        title: "Projektowanie UI",
        list: [
          "Przygotowanie makiet",
          "Projektowanie elementów UI",
          "Tworzenie indywidualnych widoków",
          "Projektowanie systemu animacji",
        ],
      },
      {
        title: "Testy",
        list: [
          "Testy funkcjonalnego prototypu",
          "Analiza uwag",
          "Ewentualne poprawki funkcjonalne",
          "Przekazanie projektu do realizacji",
        ],
      },
    ],
  },
};
